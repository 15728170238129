<template>
	<div v-if="loading">
		<LoadingSpinnner />
	</div>
	<div v-else>
		<div v-if="error" class="box">
			<font-awesome-icon icon="exclamation-triangle" class="text-danger icon" />
			<h1>{{ $t("verifyPlane.error") }}</h1>
			<h6 v-if="alreadyVerifiedError">{{ $t("verifyPlane.alreadyVerified") }}</h6>
			<h6 v-else>{{ $t("verifyPlane.errorText") }}</h6>
		</div>
		<div v-else>
			<div v-if="verified" class="box">
				<font-awesome-icon icon="check-circle" class="text-success icon" />
				<h1>{{ $t("verifyPlane.verified") }}</h1>
				<h6>{{ $t("verifyPlane.verifiedText") }}</h6>
				<div class="features">
					<ul v-html="$t('verifyPlane.verifyFeatures')"> </ul>
				</div>
				<div class="d-flex align-items-center justify-content-center" v-if="plane">
					<plane-card :plane="plane" :horizontal="true"  style="width: 100%; max-width: 500px;" @click="viewPlane">
							<Button class="btn btn-primary" @click="viewPlane">
								<font-awesome-icon icon="eye" />
								{{ $t("verifyPlane.viewPlane") }}
							</Button>
					</plane-card>
				</div>
			</div>
			<div v-else-if="denied" class="box">
				<font-awesome-icon icon="times-circle" class="text-danger icon" />
				<h1>{{ $t("verifyPlane.denied") }}</h1>
				<h6>{{ $t("verifyPlane.deniedText") }}</h6>
				<div class="features">
					<ul v-html="$t('verifyPlane.deniedFeatures')"> </ul>
				</div>
			</div>
			<div v-else class="box">
				<font-awesome-icon icon="exclamation-triangle" class="text-danger icon" />
				<h1>{{ $t("verifyPlane.error") }}</h1>
				<h6 v-if="alreadyVerifiedError">{{ $t("verifyPlane.alreadyVerified") }}</h6>
				<h6 v-else>{{ $t("verifyPlane.errorText") }}</h6>
				<div class="features">
					<ul v-html="$t('verifyPlane.verifyFeatures')"> </ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Fade } from "@egjs/flicking-plugins";
import LoadingSpinnner from "@/components/LoadingSpinner.vue";
import _ from "lodash";
import { scrollToTop } from "@/plugins/utils";
import CustomPlaneFromPlane from "@/components/plane/customPlaneForm/CustomPlaneFromPlane.vue";
import CustomPlaneFormProfile from "@/components/plane/customPlaneForm/CustomPlaneFormProfile.vue";
import CustomPlaneFormCogData from "@/components/plane/customPlaneForm/CustomPlaneFormCogData.vue";
import CustomPlaneFormEmergencyInstruments
	from "@/components/plane/customPlaneForm/CustomPlaneFormEmergencyInstruments.vue";
import CustomPlaneFormEquipments from "@/components/plane/customPlaneForm/CustomPlaneFormEquipments.vue";
import PlaneCard from "@/components/plane/PlaneCard.vue";

export default {
	name: "VerifyPlaneView",
	components: {
		PlaneCard,
		LoadingSpinnner,
	},
	data() {
		return {
			loading: true,
			error: null,
			verified: false,
			denied: false,
			alreadyVerifiedError: false,
			plane: null,
		};
	},
	props: {
		msgs: Object
	},
	methods: {
		viewPlane() {
			this.$router.push({ name: "plane", params: { id: this.plane.id } });
		}
	},
	async beforeMount() {
		const planeId = this.$route.params.id;

		this.plane = await this.$rest.loadPlane(Number(planeId));

		const verify = this.$route.query.result === "1";
		const token = this.$route.query.token;
		if(!token || !planeId) {
			this.error = true;
			this.alreadyVerifiedError = false;
			this.loading = false;
			return;
		}

		const result = verify ? await this.$rest.verifyPlane(token) : await this.$rest.denyPlane(token);
		if(result) {
			this.verified = verify;
			this.denied = !verify;
		} else {
			this.error = true;
			this.alreadyVerifiedError = true;
		}

		this.loading = false;
	}
};

</script>

<style scoped>
.box {
	margin-top: 3rem;
	text-align: center;
	width: 100%;
}
.icon {
	font-size: 5rem;
}
h1 {
	font-size: 2rem;
	margin-top: 1rem;
}

.features {
	display: flex;
	justify-content: center;
	padding: 0 1rem 0 0rem;
}

ul {
	text-align: left;
	margin-top: 1rem;
	max-width: 400px;
}
</style>
